import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { extractContentModeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import {
	resetCustomEntityWithoutCoreFields,
	updateCustomEntity,
} from '../../../../../../store/action-creators/custom-entities-action-creator';
import { useTranslation } from 'react-i18next';
import SelectInputDesign, { SelectOption } from '../../../../../Partials/design-components/select/select';
import { CustomEntitiesSelectOption, IDomain } from '../../../models/models';
import CustomEntitiesHttpService from '../../../../../../services/rest/custom-entities.http.service';
import { toast } from 'react-toastify';
import {
	CustomEntitiesTypes,
	DetailsErrorState,
	ICustomEntitiesEntityType,
	UpdateCustomEntityReduxType,
} from '../../../helpers/custom-entities.helper';
import i18next from 'i18next';

const customEntitiesTypeSelectOptions: ICustomEntitiesEntityType[] = [
	{ name: i18next.t('person'), value: CustomEntitiesTypes.PERSON },
	{ name: i18next.t('organization'), value: CustomEntitiesTypes.ORGANIZATION },
	{ name: i18next.t('place'), value: CustomEntitiesTypes.PLACE },
	{ name: i18next.t('role'), value: CustomEntitiesTypes.ROLE },
];

type Props = {
	customEntityType: string;
	customEntityDomain: IDomain;
	isCreateMode: boolean;
	errorState: DetailsErrorState;
	resetCustomEntityWithoutCoreFields: () => void;
	updateCustomEntity: UpdateCustomEntityReduxType;
};

const CoreFields: FunctionComponent<Props> = ({
	customEntityDomain,
	customEntityType,
	isCreateMode,
	updateCustomEntity,
	resetCustomEntityWithoutCoreFields,
	errorState,
}) => {
	const { t } = useTranslation();
	const [domains, setDomains] = useState<CustomEntitiesSelectOption[]>([]);

	useEffect(() => {
		if (isCreateMode) {
			CustomEntitiesHttpService.getDomains()
				.then((response) => response.data.results)
				.then((data) => {
					const mappedData: CustomEntitiesSelectOption[] = data.map((item: any) => {
						return { name: item.name, value: item.id, data: item.slug };
					});

					setDomains(mappedData);
				})
				.catch(() => toast.error(t('error_fetching_domains')));
		}
	}, []);

	const onDomainChange = (option: SelectOption) => {
		resetCustomEntityWithoutCoreFields();
		updateCustomEntity({
			contained_in_domain: { id: option.value, slug: option.data, name: option.name },
			entity_type: customEntityType,
		});
	};

	const onEntityTypeChange = (option: SelectOption) => {
		resetCustomEntityWithoutCoreFields();
		updateCustomEntity({
			contained_in_domain: customEntityDomain,
			entity_type: option.value,
		});
	};

	return (
		<Row>
			<Col>
				<SelectInputDesign
					invokeFunc={onDomainChange}
					fieldId='domain-select'
					labelText={t('domain')}
					placeholderText={t('select')}
					options={domains}
					selectedValue={customEntityType === CustomEntitiesTypes.ROLE ? undefined : customEntityDomain.id}
					disabled={!isCreateMode || customEntityType === CustomEntitiesTypes.ROLE}
					disabledValue={customEntityType === CustomEntitiesTypes.ROLE ? undefined : customEntityDomain.name}
					errorMessage={errorState.domain ? t('domain_is_mandatory') : undefined}
				/>
			</Col>
			<Col>
				<SelectInputDesign
					invokeFunc={onEntityTypeChange}
					fieldId='entity-type-select'
					labelText={t('entity_type')}
					placeholderText={t('select')}
					options={customEntitiesTypeSelectOptions}
					selectedValue={customEntityType}
					disabled={!isCreateMode}
					errorMessage={errorState.entity_type ? t('entity_type_is_mandatory') : undefined}
				/>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		customEntityType: state.customEntities[contentMode].entity_type,
		customEntityDomain: state.customEntities[contentMode].contained_in_domain || {},
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
		resetCustomEntityWithoutCoreFields: () => dispatch(resetCustomEntityWithoutCoreFields(contentMode)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CoreFields);
