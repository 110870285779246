import React from 'react';
import i18next from 'i18next';
import { CustomEntitiesTypes } from '../views/Pages/CustomEntities/helpers/custom-entities.helper';
import { CustomEntitiesSelectOption, CustomEntityBasic } from '../views/Pages/CustomEntities/models/models';
import { DATA_QA_ATTRIBUTES } from '../views/Partials/Sidebar/tags-refactored/constants/data-qa';
import { ICustomEntitiesReduxStore } from '../store/reducers/content-sidebar-tags.reducer';
import Related from '../models/related/Related';
import { relatedConstants } from '../constants/related.constants';
import { generateDefaultImageByEntityType } from './global.helpers';
import { DisplayAsset } from '../models/v2/competition/entity/competition.model';
import SportsEntityModel from '../models/v2/sports-types/sports-entity.model';
import SportsTypesModel from '../models/v2/sports-types/sports-types.model';
import { SportTypes } from '../constants/sport.types';
import { ISuggestedEntity } from '../views/Partials/Sidebar/tags-refactored/helpers/suggested-entities-v2.helper';

export const isSportDomain = (domain: string) => {
	return domain.toLocaleLowerCase() === 'sport';
};

export const isFootballSelected = (selectedSport?: SportsTypesModel | null) => {
	return selectedSport && selectedSport.sport === SportTypes.FOOTBALL ? true : false;
};

export const customConnectionsToOptions = (customConnections: CustomEntityBasic[]): CustomEntitiesSelectOption[] | null => {
	if (customConnections && customConnections.length > 0) {
		return customConnections.map((connection) => ({
			name: connection.name,
			value: connection.id,
			data: connection.slug,
			additional: connection,
		}));
	}

	return null;
};

export const generateCustomOptionInfoDataByEntityType = (entity: CustomEntitiesSelectOption): string[] => {
	if (!entity.additional) return [];

	const dataAsDynamic = entity as any; // this is because we should access properties by entity type and the ts is not allowing it
	const parentPlace = (dataAsDynamic.additional.contained_in_place && dataAsDynamic.additional.contained_in_place.name) || '';
	const parentOrganization =
		(dataAsDynamic.additional.contained_in_organization && dataAsDynamic.additional.contained_in_organization.name) || '';

	switch (dataAsDynamic.additional.entity_type) {
		case CustomEntitiesTypes.ORGANIZATION:
			return [parentPlace, parentOrganization];
		case CustomEntitiesTypes.PERSON:
			const nationality = (dataAsDynamic.additional.nationality && dataAsDynamic.additional.nationality.name) || '';
			const gender: string = (dataAsDynamic.additional.gender || '').toLocaleLowerCase();
			return [nationality, gender ? i18next.t(gender) : ''];
		case CustomEntitiesTypes.PLACE:
			return [parentPlace];
		default:
			return [];
	}
};

const getDataQaAttributeByType = (entityType: string | null) => {
	switch (entityType) {
		case CustomEntitiesTypes.ORGANIZATION:
			return DATA_QA_ATTRIBUTES.CUSTOM_ENTITY_CONNECTIONS_ORGANIZATION;
		case CustomEntitiesTypes.PERSON:
			return DATA_QA_ATTRIBUTES.CUSTOM_ENTITY_CONNECTIONS_PERSON;
		case CustomEntitiesTypes.PLACE:
			return DATA_QA_ATTRIBUTES.CUSTOM_ENTITY_CONNECTIONS_PLACE;
		case CustomEntitiesTypes.ROLE:
			return DATA_QA_ATTRIBUTES.CUSTOM_ENTITY_CONNECTIONS_ROLE;
		default:
			return '';
	}
};

export const generateCustomConnectionsInfoProps = (entityTypeData: CustomEntitiesSelectOption[]) => {
	const entityType = entityTypeData[0] && entityTypeData[0].additional ? entityTypeData[0].additional.entity_type : null;

	return {
		value: entityTypeData,
		label: i18next.t(`custom_entity_select_${entityType}`),
		dataQa: getDataQaAttributeByType(entityType),
		emptyLoadAction: true,
		entityType: entityType || '',
	};
};

export const getFlatCustomEntitiesConnections = (customEntitiesConnections: ICustomEntitiesReduxStore): CustomEntitiesSelectOption[] => {
	return Object.values(customEntitiesConnections).flat();
};

export const apiFormatCustomEntitiesConnections = (customEntitiesConnectionsRedux: ICustomEntitiesReduxStore): Related[] => {
	return getFlatCustomEntitiesConnections(customEntitiesConnectionsRedux).map((entity) => {
		return {
			provider: relatedConstants.providers.sports,
			type: entity.additional.entity_type,
			data: entity.additional,
		} as Related;
	});
};

// opposite of apiFormatCustomEntitiesConnections
const reduxFormatCustomEntitiesConnections = (customEntitiesConnectionsApi: Related[]): ICustomEntitiesReduxStore => {
	return customEntitiesConnectionsApi.reduce((acc, item) => {
		const entityType = item.type;

		const option: CustomEntitiesSelectOption = {
			name: item.data.name || 'Unknown',
			value: item.data.id,
			additional: item.data,
		};

		// If this entityType doesn't exist yet, create an array
		if (!acc[entityType]) {
			acc[entityType] = [];
		}

		// Add the option to the appropriate array
		acc[entityType].push(option);

		return acc;
	}, {} as ICustomEntitiesReduxStore);
};

export const extractCustomEntitiesFromRelatedApi = (dataFromApi: Related[]) => {
	const customEntitiesTypesArray = Object.values(CustomEntitiesTypes);
	const customEntitiesData = dataFromApi.filter((item: Related) => (customEntitiesTypesArray as string[]).includes(item.type));
	return reduxFormatCustomEntitiesConnections(customEntitiesData);
};

export const mergeRelatedEntities = (props: any) => {
	// this is just to make reusable function rather than adding changes to 100 places, this is why is any type
	// some old logic refactoring

	let relatedContent = props.relatedContent || [];
	let relatedMatches = props.relatedMatches || [];
	let relatedSports = props.relatedSports || [];
	let relatedTags = props.relatedTags || [];
	let relatedCustomEntities = props.relatedCustomEntities || [];

	return [...relatedContent, ...relatedSports, ...relatedTags, ...relatedMatches, ...relatedCustomEntities];
};

const generateSportOptionInfoDataByEntityType = (entity: any): string[] => {
	if (!entity) return [];
	return [entity.country.name, entity.gender];
};

export const getImageAsset = (displayAsset: DisplayAsset | any, entityType: string): string => {
	return displayAsset && displayAsset.url ? displayAsset.url : generateDefaultImageByEntityType(entityType);
};

export const formatImageAsDisplayAsset = (entity: ISuggestedEntity): DisplayAsset => {
	if (isFootballEntity(entity)) {
		const footballUrlImage = typeof entity.url_image === 'string' ? entity.url_image : null;
		const footballUrlLogo = typeof entity.url_logo === 'string' ? entity.url_logo : null;
		const footballUrlThumb = typeof entity.url_thumb === 'string' ? entity.url_thumb : null;

		return { url: footballUrlImage || footballUrlLogo || footballUrlThumb || '' };
	}

	return entity.display_asset;
};

export const generateCustomOptionHtmlOption = (
	innerProps: Record<string, unknown>,
	entity_type: string,
	value: string,
	display_asset: DisplayAsset,
	name: string,
	additionalData: string[],
	removeSuggestedEntityFunc?: {
		func: Function;
		data: ISuggestedEntity;
	},
) => {
	const image = getImageAsset(display_asset, entity_type);

	const handleRemove = (e: React.MouseEvent) => {
		e.stopPropagation();
		if (removeSuggestedEntityFunc && removeSuggestedEntityFunc.data) {
			removeSuggestedEntityFunc.func(removeSuggestedEntityFunc.data);
		}
	};

	return (
		<div {...innerProps} key={value}>
			<div id={`connections-drop-down-option-${value}`} className='option-container-dropdown option-container option-border'>
				<img className={`option-logo-${entity_type}`} width={23} height={23} src={image} onError={(i: any) => (i.target.src = image)} />
				<div>
					<div className='option-label'> {name} </div>
					{additionalData && <span className='option-label-additional'> {additionalData.filter((el) => el).join(' / ')} </span>}
				</div>
				{removeSuggestedEntityFunc && (
					<div className='remove-button' onClick={handleRemove}>
						<span>x</span>
					</div>
				)}
			</div>
		</div>
	);
};

export const CustomEntitiesOption = ({ data, innerProps }: { data: CustomEntitiesSelectOption; innerProps: Record<string, unknown> }) => {
	const { additional } = data;
	const { name, entity_type, display_asset, id } = additional;
	const additionalData = generateCustomOptionInfoDataByEntityType(data);
	return generateCustomOptionHtmlOption(innerProps, entity_type, id, display_asset, name, additionalData);
};

export const SportCustomOption = ({
	data,
	innerProps,
}: {
	data: {
		value: string;
		label: string;
		data: SportsEntityModel;
	} | null;
	innerProps: Record<string, unknown>;
}) => {
	if (!data) return null;

	const entityData = data.data;
	const { name, entity_type, id } = entityData;
	const display_asset = formatImageAsDisplayAsset((entityData as unknown) as ISuggestedEntity);
	const additionalData = generateSportOptionInfoDataByEntityType(entityData);
	return generateCustomOptionHtmlOption(innerProps, entity_type, id, display_asset, name, additionalData);
};

export const isCustomEntityType = (entityType: string) => {
	return (Object.values(CustomEntitiesTypes) as string[]).includes(entityType);
};

export const isFootballEntity = (entity: ISuggestedEntity) => {
	return !!entity.uuid;
};

export const mergeUniqueCustomEntities = (
	incomingData: CustomEntitiesSelectOption[],
	alreadyAddedData: ICustomEntitiesReduxStore,
): ICustomEntitiesReduxStore => {
	const updatedStore = { ...alreadyAddedData };

	incomingData.forEach((incomingEntity) => {
		const entityType = incomingEntity.additional.entity_type;

		if (!updatedStore[entityType]) {
			updatedStore[entityType] = [];
		}

		// Check if entity with same ID already exists
		const existingEntityIndex = updatedStore[entityType].findIndex((existing) => existing.additional.id === incomingEntity.additional.id);

		// Add only if entity doesn't exist
		if (existingEntityIndex === -1) {
			updatedStore[entityType].push({ ...incomingEntity });
		}
	});

	return updatedStore;
};
