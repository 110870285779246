import { relatedConstants } from '../../../../../constants/related.constants';
import Related from '../../../../../models/related/Related';
import { DisplayAsset } from '../../../../../models/v2/competition/entity/competition.model';
import { CustomEntitiesTypes } from '../../../../Pages/CustomEntities/helpers/custom-entities.helper';
import { CustomEntityBasic, IOrganization, IPerson, IPlace } from '../../../../Pages/CustomEntities/models/models';
import slug from '../../seo-refactored/subcomponents/slug/slug';

export interface ISuggestedEntity {
	origin_ids: string[];
	hide_from_suggested: boolean;
	id: string;
	name: string;
	slug: string;
	entity_type: string;
	display_asset: DisplayAsset;
	[key: string]: unknown;
}

export const isEntityTypeValidForSuggestedEntities = (entityType: string) => {
	const enabledEntityTypes = [
		relatedConstants.types.coach,
		relatedConstants.types.team,
		relatedConstants.types.tournament,
		relatedConstants.types.competition,
		relatedConstants.types.player,
		CustomEntitiesTypes.ORGANIZATION,
		CustomEntitiesTypes.PERSON,
		CustomEntitiesTypes.PLACE,
	];

	return enabledEntityTypes.includes(entityType);
};

export const formatSuggestedEntityAsCustomEntity = (item: ISuggestedEntity) => (item as unknown) as CustomEntityBasic;

export const formatSportEntityAsRelatedModel = (item: ISuggestedEntity) => {
	return {
		type: item.entity_type,
		provider: item.uuid ? relatedConstants.providers.football : relatedConstants.providers.sports, // if it has uuid it is football entity
		data: { ...item },
	} as Related;
};

export const isSportRelatedEntityAlreadyExist = (reduxAlreadyAddedSportEntities: Related[], suggestedEntity: Related) => {
	return reduxAlreadyAddedSportEntities.findIndex((entity) => entity.data.id === suggestedEntity.data.id) < 0;
};

export const suggestedEntitiesAdditionalCacheQuery = {
	staleTime: 60000, // Cache for 60 seconds
	keepPreviousData: true, // Keep previous data while loading new data
};

export const extractPropsForSidebarSuggestedEntities = (entity: Record<string, any>, paragraphId: string): ISuggestedEntity | null => {
	// its any because it is used in js file
	let result = null;
	switch (entity.entity_type) {
		case CustomEntitiesTypes.ORGANIZATION:
			result = entity as IOrganization;
			break;
		case CustomEntitiesTypes.PERSON:
			result = entity as IPerson;
			break;
		case CustomEntitiesTypes.PLACE:
			result = entity as IPlace;
			break;
	}

	return result ? ({ ...result, hide_from_suggested: false, origin_ids: [paragraphId] } as ISuggestedEntity) : null;
};
