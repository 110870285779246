import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { extractContentModeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { updateCustomEntity } from '../../../../../../store/action-creators/custom-entities-action-creator';
import { UpdateCustomEntityReduxType, fetchPlaceToOptions, generateDisclaimerHtmlElement } from '../../../helpers/custom-entities.helper';
import { IPlace } from '../../../models/models';
import DynamicSelectInputDesign from '../../../../../Partials/design-components/dynamic-select/dynamic-select';
import { useTranslation } from 'react-i18next';
import WikiPageSelect from './wiki';
import '../details.scss';

type Props = {
	customEntityContainedPlace: IPlace['contained_in_place'];
	updateCustomEntity: UpdateCustomEntityReduxType;
};

const PlaceContained: FunctionComponent<Props> = ({ customEntityContainedPlace, updateCustomEntity }) => {
	const { t } = useTranslation();

	return (
		<div id={DATA_QA.OPTIONAL_FIELDS}>
			<Row>
				<Col md='6'>
					<DynamicSelectInputDesign
						invokeFunc={(option) => updateCustomEntity({ contained_in_place: { id: option.value, name: option.label, slug: option.data.slug } })}
						fetchResults={fetchPlaceToOptions}
						fieldId='contained_in_place'
						labelText={t('contained_in_place')}
						selectedValue={
							customEntityContainedPlace
								? { value: customEntityContainedPlace.id, label: customEntityContainedPlace.name, data: customEntityContainedPlace }
								: undefined
						}
					/>
				</Col>
				<WikiPageSelect />
			</Row>
			<div className='message_label_disclaimer_container'>
				{generateDisclaimerHtmlElement('contained_in_place_disclaimer', 'contained_in_place_disclaimer')}
			</div>
		</div>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		customEntityContainedPlace: state.customEntities[contentMode].contained_in_place || null,
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceContained);
