import React, { FunctionComponent, useCallback } from 'react';
import { CustomEntitiesSelectOption, CustomEntityBasic } from '../../../../../../Pages/CustomEntities/models/models';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { DATA_QA_ATTRIBUTES } from '../../../../../Sidebar/tags-refactored/constants/data-qa';
import { customConnectionColorStyles } from '../../../../../Sidebar/tags-refactored/constants/styles';
import { generateCustomOptionWithImage } from '../../../../../Sidebar/tags-refactored/helpers/utils';
import { customConnectionsToOptions, CustomEntitiesOption } from '../../../../../../../global-helpers/sidebar.helpers';
import CustomEntitiesHttpService from '../../../../../../../services/rest/custom-entities.http.service';

type Properties = {
	domain: CustomEntitiesSelectOption | null;
	entityType: string;
	selectedCustomEntities: CustomEntityBasic[];
	onCustomEntitiesChange: (customEntities: CustomEntityBasic[]) => void;
};

const AdvContentFiltersCustomEntitiesSelect: FunctionComponent<Properties> = ({
	domain,
	entityType,
	selectedCustomEntities,
	onCustomEntitiesChange,
}) => {
	const { t } = useTranslation();

	const debouncedLoadOptions = useCallback(
		debounce((inputValue: string, callback: (options: CustomEntitiesSelectOption[]) => void) => {
			loadConnectionsOnType(inputValue).then(callback);
		}, 300),
		[domain],
	);

	if (!domain) return null;

	const selectedCustomEntitiesAsOptions = customConnectionsToOptions(selectedCustomEntities) || [];

	const modifyConnections = (action: { action: string; removedValue?: CustomEntitiesSelectOption; option?: CustomEntitiesSelectOption }) => {
		switch (action.action) {
			case 'clear':
				onCustomEntitiesChange([]);
				break;
			case 'remove-value':
				const newData = selectedCustomEntities.filter((entity) => action.removedValue && entity.id !== action.removedValue.value);
				onCustomEntitiesChange(newData);
				break;
			case 'select-option':
				action.option && onCustomEntitiesChange([...selectedCustomEntities, action.option.additional]);
				break;
		}
	};

	const loadConnectionsOnType = async (input: string) => {
		if (input.length < 2) {
			return Promise.resolve([]);
		}

		try {
			const response = await CustomEntitiesHttpService.getDomainEntities(domain.data!!, entityType, input, 30);
			const data: CustomEntityBasic[] = (await response.data.results) || [];
			const customOptions = customConnectionsToOptions(data);

			if (!customOptions) {
				return [];
			}

			return customOptions.map((option) => ({
				...option, // Keep all original data
				label: option.name, // Add label property that react-select needs
			}));
		} catch (error) {
			console.error('Error fetching domain entities:', error);
			return [];
		}
	};

	const loadOptions = (inputValue: string) => {
		return new Promise((resolve) => {
			debouncedLoadOptions(inputValue, resolve);
		});
	};

	return (
		<FormGroup data-qa='domain-type-select'>
			<Label htmlFor={DATA_QA_ATTRIBUTES.CUSTOM_ENTITY_SELECT}>{t('custom_entity_select')}</Label>
			<AsyncSelect
				className='custom-entities-select-sidebar'
				id={DATA_QA_ATTRIBUTES.CUSTOM_ENTITY_SELECT}
				styles={customConnectionColorStyles}
				isMulti
				isClearable
				placeholder={t('search_and_select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				loadOptions={loadOptions}
				onChange={(_, action) => modifyConnections(action)}
				value={selectedCustomEntitiesAsOptions}
				formatOptionLabel={(option) =>
					generateCustomOptionWithImage(
						option.name,
						option.value,
						(option.additional && option.additional.display_asset && option.additional.display_asset.url) || '',
						(option.additional && option.additional.entity_type) || '',
					)
				}
				components={{
					Option: CustomEntitiesOption,
				}}
			/>
		</FormGroup>
	);
};

export default AdvContentFiltersCustomEntitiesSelect;
