import React, { FunctionComponent, useMemo } from 'react';
import { FormGroup, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { CustomEntitiesTypes, ICustomEntitiesEntityType } from '../../../../../../Pages/CustomEntities/helpers/custom-entities.helper';
import Select from 'react-select';

export const CustomEntitiesTypeSelect: FunctionComponent<{
	selectedEntityType: ICustomEntitiesEntityType;
	onEntityTypeSelectFunc: (data: ICustomEntitiesEntityType) => void;
}> = ({ selectedEntityType, onEntityTypeSelectFunc }) => {
	const { t } = useTranslation();

	const onCustomEntityTypeSelect = (selection: ICustomEntitiesEntityType) => onEntityTypeSelectFunc(selection);

	const contentFiltersCustomEntitiesTypeSelectOptions = useMemo(
		() => [
			{ label: t('person'), value: CustomEntitiesTypes.PERSON },
			{ label: t('organization'), value: CustomEntitiesTypes.ORGANIZATION },
			{ label: t('place'), value: CustomEntitiesTypes.PLACE },
		],
		[],
	);

	return (
		<FormGroup data-qa='entity-type-select'>
			<Label htmlFor='entity-type-select' className='ml-1'>
				{t('entity_type')}
			</Label>

			<Select
				isClearable
				options={contentFiltersCustomEntitiesTypeSelectOptions}
				inputId='entity-type-select'
				className='w-100 mb-4'
				placeholder={t('select')}
				value={selectedEntityType}
				onChange={onCustomEntityTypeSelect}
				on
			/>
		</FormGroup>
	);
};
