import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa';
import CustomEntitiesHttpService from '../../../../../../services/rest/custom-entities.http.service';
import { CustomEntitiesSelectOption } from '../../../../../Pages/CustomEntities/models/models';
import { toast } from 'react-toastify';
import SelectInputDesign, { SelectOption } from '../../../../design-components/select/select';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateSidebarDomain } from '../../../../../../store/action-creators/content-sidebar-tags';

type Properties = {
	updateDomainInRedux: (domain: CustomEntitiesSelectOption) => void;
	domain: CustomEntitiesSelectOption | null;
};

const DomainSelect: FunctionComponent<Properties> = ({ updateDomainInRedux, domain }) => {
	const { t } = useTranslation();
	const [domains, setDomains] = useState<CustomEntitiesSelectOption[]>([]);

	useEffect(() => {
		CustomEntitiesHttpService.getDomains()
			.then((response) => response.data.results)
			.then((data) => {
				const mappedData: CustomEntitiesSelectOption[] = data.map((item: any) => {
					return { name: item.name, value: item.id, data: item.slug };
				});

				setDomains(mappedData);
				updateDomainInRedux(mappedData[0]);
			})
			.catch(() => toast.error(t('error_fetching_domains')));
	}, []);

	const onDomainChange = (option: SelectOption) => {
		updateDomainInRedux(option as CustomEntitiesSelectOption);
	};

	return (
		<Row>
			<Col>
				<FormGroup>
					<SelectInputDesign
						invokeFunc={onDomainChange}
						fieldId={DATA_QA_ATTRIBUTES.DOMAIN_SELECT}
						labelText={t('domain')}
						placeholderText={t('select')}
						options={domains}
						selectedValue={domain && domain.value ? domain.value : undefined}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	return {
		domain: (state.contentSidebar.tags && state.contentSidebar.tags.domain) || null,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateDomainInRedux: (domain: CustomEntitiesSelectOption) => dispatch(updateSidebarDomain(domain)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainSelect);
