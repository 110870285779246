import React, { FunctionComponent } from 'react';
import { Button, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CustomEntityBasic } from '../../../../../Pages/CustomEntities/models/models';
import {
	formatImageAsDisplayAsset,
	generateCustomOptionHtmlOption,
	isCustomEntityType,
} from '../../../../../../global-helpers/sidebar.helpers';
import Related from '../../../../../../models/related/Related';
import { extractActionForUpdatingSportsConnections, extractRelatedPropertiesNameByUrl } from '../../helpers/utils';
import { addSidebarCustomEntities } from '../../../../../../store/action-creators/content-sidebar-tags';
import {
	formatSportEntityAsRelatedModel,
	formatSuggestedEntityAsCustomEntity,
	isSportRelatedEntityAlreadyExist,
	ISuggestedEntity,
} from '../../helpers/suggested-entities-v2.helper';
import { hideSuggestedEntity } from '../../../../../../store/action-creators/suggested-entities';

interface ISuggestedEntitiesProps {
	reduxAlreadyAddedSportEntities: Related[];
	reduxSuggestedEntities: ISuggestedEntity[];
	addSidebarCustomEntitiesRedux: (customEntities: CustomEntityBasic[]) => void;
	updateSportsConnectionRedux: (connections: Related[]) => void;
	hideSuggestedEntityRedux: (entity: ISuggestedEntity) => void;
}

const SuggestedEntities: FunctionComponent<ISuggestedEntitiesProps> = ({
	reduxAlreadyAddedSportEntities,
	reduxSuggestedEntities,
	updateSportsConnectionRedux,
	addSidebarCustomEntitiesRedux,
	hideSuggestedEntityRedux,
}) => {
	const { t } = useTranslation();
	const displayedSuggestedEntities = reduxSuggestedEntities.filter((entity) => !entity.hide_from_suggested);

	const addSuggestedEntity = (suggestedEntity: ISuggestedEntity) => {
		if (isCustomEntityType(suggestedEntity.entity_type)) {
			const typifiedData = formatSuggestedEntityAsCustomEntity(suggestedEntity);
			typifiedData && addSidebarCustomEntitiesRedux([typifiedData]);
		} else {
			const dataAsRelatedModel = formatSportEntityAsRelatedModel(suggestedEntity);
			if (reduxAlreadyAddedSportEntities.findIndex((entity) => entity.data.id === suggestedEntity.id) < 0) {
				updateSportsConnectionRedux([...reduxAlreadyAddedSportEntities, dataAsRelatedModel]);
			}
		}
	};

	const addAllSuggestedEntities = () => {
		const filteredSuggestedData = displayedSuggestedEntities.reduce(
			(acc, item) => {
				if (isCustomEntityType(item.entity_type)) {
					acc.custom.push(formatSuggestedEntityAsCustomEntity(item));
				} else {
					const sportEntityAsRelated = formatSportEntityAsRelatedModel(item);
					isSportRelatedEntityAlreadyExist(reduxAlreadyAddedSportEntities, sportEntityAsRelated) && acc.sports.push(sportEntityAsRelated);
				}
				return acc;
			},
			{ custom: [] as CustomEntityBasic[], sports: [] as Related[] },
		);

		if (filteredSuggestedData.custom.length > 0) {
			addSidebarCustomEntitiesRedux(filteredSuggestedData.custom);
		}

		if (filteredSuggestedData.sports.length > 0) {
			updateSportsConnectionRedux([...reduxAlreadyAddedSportEntities, ...filteredSuggestedData.sports]);
		}
	};

	return (
		<FormGroup className='suggested-entities-container'>
			<div>
				<span className='label'>
					{displayedSuggestedEntities.length > 0 ? t('recognized_entities_title') : t('no_recognized_entities_title')}
				</span>
				<span className='sublabel'>({t('auto_generated')})</span>
			</div>
			<div className='suggested-entities-holder'>
				{displayedSuggestedEntities.map((entity) => {
					return generateCustomOptionHtmlOption(
						{ className: `suggested-entity suggested-entity-${entity.entity_type}`, onClick: () => addSuggestedEntity({ ...entity }) },
						entity.entity_type,
						entity.id,
						formatImageAsDisplayAsset(entity),
						entity.name,
						[],
						{ func: hideSuggestedEntityRedux, data: entity },
					);
				})}
			</div>

			{displayedSuggestedEntities.length > 0 && (
				<Button
					id='add-all-suggested-entities'
					className='mb-2 mt-2'
					color='outline-primary'
					style={{ width: '100%' }}
					onClick={addAllSuggestedEntities}
				>
					{t('add_all')}
				</Button>
			)}
		</FormGroup>
	);
};

const mapStateToProps = (state: any) => {
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();

	return {
		reduxAlreadyAddedSportEntities: (nestedReduxPropertyName && state.tempSportsRelated[nestedReduxPropertyName]) || [],
		reduxSuggestedEntities: state.suggestedEntities.suggestedEntities || [],
	};
};

const mapDispatchToProps = (dispatch: any) => {
	const updateSportsConnectionAction = extractActionForUpdatingSportsConnections();

	return {
		updateSportsConnectionRedux: (connections: Related[]) =>
			updateSportsConnectionAction && dispatch(updateSportsConnectionAction(connections)),
		addSidebarCustomEntitiesRedux: (customEntities: CustomEntityBasic[]) => dispatch(addSidebarCustomEntities(customEntities)),
		hideSuggestedEntityRedux: (entity: ISuggestedEntity) => dispatch(hideSuggestedEntity(entity)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedEntities);
