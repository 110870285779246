import AdvancedFilterModel from './advanced-content-filter.model';
import { AdvancedFilterJson } from './advanced-content-filter.json';
import Related from '../../../../../models/related/Related';
import Category from '../../../../../models/category/Category';
import UserModel from '../subcomponents/user-select/models/user.model';
import ContentAttributes from '../../../../../models/content-attributes/content-attributes-model';
import { CustomEntitiesSelectOption, CustomEntityBasic } from '../../../../Pages/CustomEntities/models/models';
import { ICustomEntitiesEntityType } from '../../../../Pages/CustomEntities/helpers/custom-entities.helper';
import { ContentTypes } from '../../../../../constants/content-types';
export default class AdvancedFilterBuilder {
	private json: AdvancedFilterJson;

	constructor(content?: AdvancedFilterModel | AdvancedFilterJson) {
		if (content && content instanceof AdvancedFilterModel) {
			this.json = content.toJSON();
		} else if (content) {
			this.json = content;
		} else {
			this.json = {} as AdvancedFilterJson;
		}
	}

	withSearchText(searchText: string): AdvancedFilterBuilder {
		this.json.searchText = searchText;

		return this;
	}

	withRelatedSports(relatedSports: Related[]): AdvancedFilterBuilder {
		this.json.relatedSports = relatedSports;

		return this;
	}

	withRelatedTags(relatedTags: Related[]): AdvancedFilterBuilder {
		this.json.relatedTags = relatedTags;

		return this;
	}

	withCategory(category: Category): AdvancedFilterBuilder {
		this.json.category = category;

		return this;
	}

	withCreatedBy(createdBy: UserModel): AdvancedFilterBuilder {
		this.json.createdBy = createdBy;

		return this;
	}

	withOrigin(origin: ContentAttributes, type: string): AdvancedFilterBuilder {
		switch (type) {
			case ContentTypes.ARTICLE:
				this.json.articleOrigin = origin;
				break;
			case ContentTypes.VIDEO:
				this.json.videoOrigin = origin;
				break;
			default:
				this.json.imageOrigin = origin;
				break;
		}

		return this;
	}

	build(): AdvancedFilterModel {
		return AdvancedFilterModel.fromJSON(this.json);
	}

	withDate(date: string[]): AdvancedFilterBuilder {
		this.json.date = date;

		return this;
	}

	withTournament(tournament: Related): AdvancedFilterBuilder {
		this.json.tournament = tournament;

		return this;
	}

	withLanguage(language: any): AdvancedFilterBuilder {
		this.json.language = language;

		return this;
	}

	withSportsType(sportsType: any): AdvancedFilterBuilder {
		this.json.sportsType = sportsType;

		return this;
	}

	withType(type: ContentAttributes[]): AdvancedFilterBuilder {
		this.json.type = type;

		return this;
	}

	withStatus(status: string): AdvancedFilterBuilder {
		this.json.status = status;

		return this;
	}

	withProperties(properties: string[]): AdvancedFilterBuilder {
		this.json.properties = properties;

		return this;
	}

	withDomain(domain: CustomEntitiesSelectOption | null): AdvancedFilterBuilder {
		this.json.domain = domain;

		return this;
	}

	withEntityType(entityType: ICustomEntitiesEntityType): AdvancedFilterBuilder {
		this.json.entityType = entityType;

		return this;
	}

	withCustomEntities(customEntities: CustomEntityBasic[]): AdvancedFilterBuilder {
		this.json.customEntities = customEntities;

		return this;
	}

	toRequestJson(): AdvancedFilterJson {
		let json = {} as AdvancedFilterJson;
		json.relatedSports = this.json.relatedSports;
		json.relatedTags = this.json.relatedTags;
		json.category = this.json.category;
		json.date = this.json.date;
		json.language = this.json.language;
		json.type = this.json.type;
		json.status = this.json.status;
		json.properties = this.json.properties;
		json.domain = this.json.domain;
		json.entityType = this.json.entityType;
		json.customEntities = this.json.customEntities;

		return json;
	}
}
