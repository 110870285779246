import axios from 'axios';
import { multiLingualService } from '../../App';
import { store } from '../../store/store';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';
import {
	CUSTOM_ENTITIES_ENTITY_TYPES_DEFAULT_OPTIONS,
	CustomEntitiesTypes,
} from '../../views/Pages/CustomEntities/helpers/custom-entities.helper';
import { ICustomEntityType } from '../../views/Pages/CustomEntities/models/models';
import { toast } from 'react-toastify';
import i18next from 'i18next';

const baseURL = REACT_APP_URLS.REACT_APP_SEARCH_API_BASE_URL;

export default class CustomEntitiesHttpService {
	static getProjectLanguage = (): string => {
		const reduxState = store.getState();
		const isMultiLingual = multiLingualService.checkIfProjectIsMultiLingual(store.getState().project.currentProject.languages);

		return isMultiLingual && multiLingualService.checkRoutePath()
			? multiLingualService.setCorrectAcceptLanguageCode(reduxState.project.currentProject.languages.defaultLanguageCode.languageCode)
			: reduxState.project.currentProject.language
			? reduxState.project.currentProject.language.split('-')[0]
			: 'en';
	};

	static getGeneralInstanceHeaders = () => {
		const reduxState = store.getState();

		return {
			'X-Project': `${reduxState.project.currentProject.domain}`,
			'Accept-Language': `${CustomEntitiesHttpService.getProjectLanguage()}`,
			'Content-Type': 'application/json',
		};
	};

	static instance = () => {
		return axios.create({
			baseURL,
			timeout: 30000,
			headers: {
				...CustomEntitiesHttpService.getGeneralInstanceHeaders(),
				Authorization: 'Basic c2VhcmNoYXBpOkdVWmlZRk02eU4=',
			},
		});
	};

	static getCustomEntities = (searchQuery: string, offset: number, limit: number) => {
		if (!searchQuery.includes('entity_type')) {
			return CustomEntitiesHttpService.instance().get(
				`/suggest?${searchQuery}&entity_type=${CUSTOM_ENTITIES_ENTITY_TYPES_DEFAULT_OPTIONS}&offset=${offset}&limit=${limit}&translation_language=${CustomEntitiesHttpService.getProjectLanguage()}`,
			);
		} else {
			return CustomEntitiesHttpService.instance().get(
				`/suggest?${searchQuery}&offset=${offset}&limit=${limit}&translation_language=${CustomEntitiesHttpService.getProjectLanguage()}`,
			);
		}
	};

	static deleteCustomEntity = (id: string, type: string) => {
		return CustomEntitiesHttpService.instance().delete(`/${type}/${id}`);
	};

	static getDomains = () => {
		return CustomEntitiesHttpService.instance().get(
			`/suggest?entity_type=${CustomEntitiesTypes.DOMAIN}&translation_language=${CustomEntitiesHttpService.getProjectLanguage()}`,
		);
	};

	static getDomainEntitiesWithoutType = (domain: string, name?: string, limit?: number) => {
		const limitParam = `&limit=${limit || 200}`;
		const optionalParam = name ? `&name=${name}${limitParam}` : limitParam;
		return CustomEntitiesHttpService.instance().get(
			`/suggest?domain=${domain}${optionalParam}&translation_language=${CustomEntitiesHttpService.getProjectLanguage()}`,
		);
	};

	static getDomainEntities = (domain: string, entityType: string, name?: string, limit?: number) => {
		const limitParam = `&limit=${limit || 200}`;
		const optionalParam = name ? `&name=${name}${limitParam}` : limitParam;
		return CustomEntitiesHttpService.instance().get(
			`/suggest?domain=${domain}&entity_type=${entityType}${optionalParam}&translation_language=${CustomEntitiesHttpService.getProjectLanguage()}`,
		);
	};

	static getRoles = (limit?: number) => {
		return CustomEntitiesHttpService.instance().get(`/suggest?entity_type=${CustomEntitiesTypes.ROLE}&limit=${limit || 200}`);
	};

	static getEntity = (entityType: string, id: string) => {
		return CustomEntitiesHttpService.instance().get(
			`/${entityType}/${id}?translation_language=${CustomEntitiesHttpService.getProjectLanguage()}`,
		);
	};

	static saveEntity = (data: ICustomEntityType, history: Record<string, any>) => {
		const isCreateMode = !data.id;
		return CustomEntitiesHttpService.instance()
			.post(`/${data.entity_type}`, data)
			.then((response) => {
				if (response && response.status === 200) {
					toast.success(i18next.t('entity_saved_successfully'));
				}
				return response.data;
			})
			.then((responseData) => {
				if (isCreateMode && responseData && responseData.id) {
					const navigatePath =
						data.entity_type === CustomEntitiesTypes.ROLE
							? '/smp/custom-entities'
							: `/smp/custom-entities/edit/${data.entity_type}/${responseData.id}`;

					history.push(navigatePath);
				}
			})
			.catch((err) => {
				if (err.response && err.response.data) {
					toast.error(err.response.data.message);
				} else {
					toast.error(i18next.t('error_saving_entity'));
					console.log('Error:', err.message);
				}
			});
	};
}
