import { ISuggestedEntity } from '../../views/Partials/Sidebar/tags-refactored/helpers/suggested-entities-v2.helper';
import { isSuggestedEntityAlreadyExist } from '../../views/Partials/Sidebar/tags-refactored/helpers/suggested-entities.helper';
import {
	ADD_SUGGESTED_ENTITY,
	CLEAR_SUGGESTED_ENTITIES,
	HIDE_SUGGESTED_ENTITY,
	REMOVE_SUGGESTED_ENTITIES,
	REMOVE_SUGGESTED_ENTITIES_FROM_ORIGIN,
	UPDATE_SUGGESTED_ENTITIES,
} from '../action-creators/suggested-entities';
interface InitialState {
	suggestedEntities: [];
}

const initialState: InitialState = {
	suggestedEntities: [],
};

function suggestedEntitiesReducer(state: any = initialState, action: any) {
	if (action.type === UPDATE_SUGGESTED_ENTITIES) {
		state = Object.assign({}, state, {
			suggestedEntities: action.payload,
		});
	}

	if (action.type === ADD_SUGGESTED_ENTITY) {
		const newSuggestedEntity = action.payload;
		if (!isSuggestedEntityAlreadyExist(newSuggestedEntity, newSuggestedEntity.entity_type, state.suggestedEntities)) {
			state = Object.assign({}, state, {
				suggestedEntities: [...state.suggestedEntities, action.payload],
			});
		} else {
			// we are storing 'origin_ids' the teams from which suggested entities are suggested
			// this way if we have multiple 'Premier league' teams and we remove one of them
			// -> we will still see 'Premier league' as suggested entity
			const currentSuggestedEntitiesState = [...state.suggestedEntities];
			const duplicatedItemIndex = currentSuggestedEntitiesState.findIndex(
				(el) => el.id === newSuggestedEntity.id && el.entity_type === newSuggestedEntity.entity_type,
			);

			if (duplicatedItemIndex >= 0) {
				const newSuggestedEntityOriginTeamId = newSuggestedEntity.origin_ids.length > 0 ? newSuggestedEntity.origin_ids[0] : null;
				const duplicatedItem = currentSuggestedEntitiesState[duplicatedItemIndex];

				if (newSuggestedEntityOriginTeamId && !duplicatedItem.origin_ids.includes(newSuggestedEntityOriginTeamId)) {
					currentSuggestedEntitiesState[duplicatedItemIndex].origin_ids.push(newSuggestedEntityOriginTeamId);
					state = Object.assign({}, state, {
						suggestedEntities: [...currentSuggestedEntitiesState],
					});
				}
			}
		}
	}

	if (action.type === REMOVE_SUGGESTED_ENTITIES) {
		const newSuggestedEntitiesState: ISuggestedEntity[] = [];
		const actionTeamId = action.teamId;
		state.suggestedEntities.forEach((el: ISuggestedEntity) => {
			const elOriginsIds = el && el.origin_ids && Array.isArray(el.origin_ids) ? el.origin_ids : [];
			if (elOriginsIds.includes(actionTeamId) && elOriginsIds.length > 1) {
				// Suggested NOT only by this team
				const removedCurrentTeamIdFromOriginTeamsIds = elOriginsIds.filter((originIdEl) => originIdEl !== actionTeamId);
				const newEl = { ...el, origin_ids: removedCurrentTeamIdFromOriginTeamsIds };
				newSuggestedEntitiesState.push(newEl);
			} else if (elOriginsIds.includes(actionTeamId) && elOriginsIds.length === 1) {
				// Suggested only by this team -> we will not add the element to the new state
				return;
			} else {
				// Suggested by other teams
				newSuggestedEntitiesState.push(el);
			}
		});

		state = Object.assign({}, state, {
			suggestedEntities: newSuggestedEntitiesState,
		});
	}

	if (action.type === HIDE_SUGGESTED_ENTITY) {
		const currentSuggestedEntitiesState = [...state.suggestedEntities];
		const entityForHiding = action.payload;
		const entityForHidingStateIndex = currentSuggestedEntitiesState.findIndex(
			(el) => el.id === entityForHiding.id && el.entity_type === entityForHiding.entity_type,
		);

		if (entityForHidingStateIndex >= 0) {
			const hiddenEntity = { ...currentSuggestedEntitiesState[entityForHidingStateIndex], hide_from_suggested: true };
			currentSuggestedEntitiesState[entityForHidingStateIndex] = hiddenEntity;

			state = Object.assign({}, state, {
				suggestedEntities: currentSuggestedEntitiesState,
			});
		}
	}

	if (action.type === REMOVE_SUGGESTED_ENTITIES_FROM_ORIGIN) {
		const originIdForRemove = action.payload;

		const newSuggestedEntitiesState: ISuggestedEntity[] = state.suggestedEntities.filter((el: ISuggestedEntity) => {
			if (el && el.origin_ids && Array.isArray(el.origin_ids)) {
				return !el.origin_ids.includes(originIdForRemove);
			}

			return true;
		});

		state = Object.assign({}, state, {
			suggestedEntities: newSuggestedEntitiesState,
		});
	}

	if (action.type === CLEAR_SUGGESTED_ENTITIES) {
		state = Object.assign({}, state, {
			suggestedEntities: [],
		});
	}

	return state;
}

export default suggestedEntitiesReducer;
