import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import SelectInputDesign from '../../../../../Partials/design-components/select/select';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { extractContentModeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { updateCustomEntity } from '../../../../../../store/action-creators/custom-entities-action-creator';
import {
	CustomEntitiesTypes,
	UpdateCustomEntityReduxType,
	fetchPlaceToOptions,
	generateRemoveModalContent,
	generateDisclaimerHtmlElement,
} from '../../../helpers/custom-entities.helper';
import CustomEntitiesHttpService from '../../../../../../services/rest/custom-entities.http.service';
import { CustomEntitiesSelectOption, IPersonRole } from '../../../models/models';
import GeneralModal from '../../../../../Partials/design-components/modal/modal';
import DynamicSelectInputDesign from '../../../../../Partials/design-components/dynamic-select/dynamic-select';

type Props = {
	reduxRoles: IPersonRole[];
	customEntityDomainSlug: string;
	updateCustomEntity: UpdateCustomEntityReduxType;
};

const CustomEntityRoles: FunctionComponent<Props> = ({ reduxRoles, customEntityDomainSlug, updateCustomEntity }) => {
	const { t } = useTranslation();
	const [removeModal, setRemoveModal] = useState<number>(-1);
	const areReduxRolesEmpty = !(Array.isArray(reduxRoles) && reduxRoles.length > 0);
	const [roleSelect, setRoleSelect] = useState<CustomEntitiesSelectOption[]>([]);
	const [organizationSelect, setOrganizationSelect] = useState<CustomEntitiesSelectOption[]>([]);
	const zIndexValue = 1000;

	useEffect(() => {
		if (customEntityDomainSlug) {
			Promise.all([
				CustomEntitiesHttpService.getRoles(),
				CustomEntitiesHttpService.getDomainEntities(customEntityDomainSlug, CustomEntitiesTypes.ORGANIZATION),
			])
				.then((response) => response.map((res) => res.data.results))
				.then((data) => {
					data.forEach((item, index) => {
						const requestTypeFunc = getRequestTypeFunction(index);
						const mappedData: CustomEntitiesSelectOption[] = item.map((itemEntity: any) => {
							return { name: itemEntity.name, value: itemEntity.id };
						});
						requestTypeFunc(mappedData);
					});
				});
		}
	}, [customEntityDomainSlug]);

	const getRequestTypeFunction = (index: number) => {
		return index === 0 ? setRoleSelect : setOrganizationSelect;
	};

	const updateRolesInRedux = (rolesElIndex: number, inputType: string, value: string | number, name?: string) => {
		const newRoles = !areReduxRolesEmpty ? [...reduxRoles] : [];
		newRoles[rolesElIndex] = { ...newRoles[rolesElIndex], [inputType]: { id: value, name } };
		updateCustomEntity({ roles: newRoles });
	};

	const removeRole = () => {
		updateCustomEntity({ roles: reduxRoles.filter((_, i) => i !== removeModal) });
		setRemoveModal(-1);
	};

	return (
		<>
			<div className={DATA_QA.ROLES}>
				{reduxRoles.map((role, index) => (
					<div className={DATA_QA.ROLE_ENTITY} key={index}>
						<Row>
							<Col>
								<SelectInputDesign
									invokeFunc={(option) => updateRolesInRedux(index, CustomEntitiesTypes.ROLE, option.value)}
									fieldId={`role-select-${index}`}
									labelText={t('role')}
									placeholderText={t('select')}
									options={roleSelect}
									selectedValue={(role && role.role && role.role.id) || undefined}
									zIndex={index < 1 ? zIndexValue : zIndexValue - index - 2}
								/>
								<div>{generateDisclaimerHtmlElement('role_disclaimer', 'contained_in_place_disclaimer')}</div>
							</Col>
							<Col className='remove-role-icon'>
								<div onClick={() => setRemoveModal(index)} title={t('remove')} />
							</Col>
						</Row>
						<Row>
							<Col>
								<SelectInputDesign
									invokeFunc={(option) => updateRolesInRedux(index, CustomEntitiesTypes.ORGANIZATION, option.value)}
									fieldId={`role-organization-select-${index}`}
									labelText={t('role_organization')}
									placeholderText={t('select')}
									options={organizationSelect}
									selectedValue={(role && role.organization && role.organization.id) || undefined}
									zIndex={index < 1 ? zIndexValue - 1 : zIndexValue - index - 3}
								/>
								<div>{generateDisclaimerHtmlElement('organization_disclaimer', 'contained_in_place_disclaimer')}</div>
							</Col>
							<Col>
								<DynamicSelectInputDesign
									invokeFunc={(option) => updateRolesInRedux(index, CustomEntitiesTypes.PLACE, option.value, option.label)}
									fetchResults={fetchPlaceToOptions}
									fieldId={`role-place-select-${index}`}
									labelText={t('role_place')}
									selectedValue={role && role.place ? { value: role.place, label: role.place.name } : undefined}
								/>
								<div>{generateDisclaimerHtmlElement('place_disclaimer', 'contained_in_place_disclaimer')}</div>
							</Col>
						</Row>
					</div>
				))}
			</div>
			<GeneralModal
				isDisplayed={removeModal >= 0}
				actionButtonText={t('remove')}
				isPositiveAction={false}
				title={t('remove_role')}
				bodyHTML={generateRemoveModalContent('remove_role_description')}
				onClose={() => setRemoveModal(-1)}
				action={removeRole}
			/>
			<button id={DATA_QA.ADD_NEW_ROLE_ENTITY_BTN} onClick={() => updateCustomEntity({ roles: [...reduxRoles, {}] })}>
				<div className='add-role-icon' />
				<span>{t('add_additional_roles')}</span>
			</button>
		</>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		reduxRoles: state.customEntities[contentMode].roles || [],
		customEntityDomainSlug: state.customEntities[contentMode].contained_in_domain.slug || '',
	};
}

function mapDispatchToProps(dispatch: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		updateCustomEntity: (dataForUpdate: Record<string, unknown>) => dispatch(updateCustomEntity(contentMode, dataForUpdate)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomEntityRoles);
