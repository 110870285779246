import {
	isEntityTypeValidForSuggestedEntities,
	ISuggestedEntity,
} from '../../views/Partials/Sidebar/tags-refactored/helpers/suggested-entities-v2.helper';

export const UPDATE_SUGGESTED_ENTITIES = '[ACTION] UPDATE_SUGGESTED_ENTITIES';
export const ADD_SUGGESTED_ENTITY = '[ACTION] ADD_SUGGESTED_ENTITY';
export const REMOVE_SUGGESTED_ENTITIES_FROM_ORIGIN = '[ACTION] REMOVE_SUGGESTED_ENTITIES_FROM_ORIGIN';
export const REMOVE_SUGGESTED_ENTITIES = '[ACTION] REMOVE_SUGGESTED_ENTITIES';
export const HIDE_SUGGESTED_ENTITY = '[ACTION] HIDE_SUGGESTED_ENTITY';
export const CLEAR_SUGGESTED_ENTITIES = '[ACTION] CLEAR_SUGGESTED_ENTITIES';

export function updateSuggestedEntities(suggestedEntities: ISuggestedEntity[]) {
	return {
		type: UPDATE_SUGGESTED_ENTITIES,
		payload: suggestedEntities,
	};
}

export function addSuggestedEntity(suggestedEntity: ISuggestedEntity) {
	if (isEntityTypeValidForSuggestedEntities(suggestedEntity.entity_type || '')) {
		return {
			type: ADD_SUGGESTED_ENTITY,
			payload: suggestedEntity,
		};
	}
}

export function removeSuggestedEntitiesFromOrigin(originIdForRemove: string) {
	return {
		type: REMOVE_SUGGESTED_ENTITIES_FROM_ORIGIN,
		payload: originIdForRemove,
	};
}

export function removeSuggestedEntitiesByOrigin(suggestedEntityId: string) {
	return {
		type: REMOVE_SUGGESTED_ENTITIES,
		teamId: suggestedEntityId,
	};
}

export function hideSuggestedEntity(suggestedEntity: ISuggestedEntity) {
	if (isEntityTypeValidForSuggestedEntities(suggestedEntity.entity_type)) {
		return {
			type: HIDE_SUGGESTED_ENTITY,
			payload: suggestedEntity,
		};
	}
}

export function clearSuggestedEntities() {
	return {
		type: CLEAR_SUGGESTED_ENTITIES,
	};
}
