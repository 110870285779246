import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DATA_QA } from '../../../helpers/data-qa.properties';
import { extractContentModeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import { fetchFirstWikiToOption } from '../../../helpers/custom-entities.helper';
import { useTranslation } from 'react-i18next';
import '../details.scss';
import Wiki from '../../../../../../models/wiki/Wiki';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Col } from 'reactstrap';
import { getRightArrowIconAsCssUrl } from '../../../../../../global-helpers/global-icons.helpers';

type Props = {
	customEntityId: string;
	customEntityWikiType: string;
};

const WikiPageSelect: FunctionComponent<Props> = ({ customEntityId, customEntityWikiType }) => {
	const [wiki, setWiki] = useState<Wiki | null>(null);
	const { t } = useTranslation();

	useEffect(() => {
		if (customEntityId && customEntityWikiType) {
			fetchFirstWikiToOption(customEntityId, customEntityWikiType).then((data) => {
				setWiki(data);
			});
		}
	}, [customEntityId, customEntityWikiType]);

	if (!wiki) {
		return null;
	}

	const handleClick = (e: React.MouseEvent) => {
		e.preventDefault();
		const path = `#/smp/wiki-pages/edit/${wiki.id}`;
		window.open(path, '_blank');
	};

	return (
		<Col id={DATA_QA.CUSTOM_ENTITIES_WIKI_LINK} md='6'>
			<div className='label-wiki-page-container'>
				<label>{t('wiki_page')}</label>
				<span> &nbsp; ({t('auto_generated_from_tagged_entities')})</span>
			</div>

			<a href={`/smp/wiki-pages/edit/${wiki.id}`} onClick={handleClick} target='_blank' rel='noopener noreferrer'>
				<span>{wiki.title}</span>
				<div className='wiki-link-arrow' style={{ backgroundImage: getRightArrowIconAsCssUrl() }}></div>
			</a>
		</Col>
	);
};

function mapStateToProps(state: any) {
	const contentMode = extractContentModeBasedOnUrl();

	return {
		customEntityId: state.customEntities[contentMode].id || '',
		customEntityWikiType: state.customEntities[contentMode].entity_type || '',
	};
}

export default compose(connect(mapStateToProps), withRouter)(WikiPageSelect) as React.ComponentType;
