import { customConnectionsToOptions } from '../../global-helpers/sidebar.helpers';
import Related from '../../models/related/Related';
import { CustomEntitiesSelectOption, CustomEntityBasic } from '../../views/Pages/CustomEntities/models/models';

export const UPDATE_CONTENT_SIDEBAR_DOMAIN = '[ACTION] UPDATE_CONTENT_SIDEBAR_DOMAIN';
export const UPDATE_CONTENT_SIDEBAR_ENTITY_TYPE = '[ACTION] UPDATE_CONTENT_SIDEBAR_ENTITY_TYPE';
export const UPDATE_CONTENT_SIDEBAR_CUSTOM_ENTITIES = '[ACTION] UPDATE_CONTENT_SIDEBAR_CUSTOM_ENTITIES';
export const REMOVE_CONTENT_SIDEBAR_CUSTOM_ENTITIES = '[ACTION] REMOVE_CONTENT_SIDEBAR_CUSTOM_ENTITIES';
export const UPDATE_CONTENT_SIDEBAR_CUSTOM_ENTITIES_BULK = '[ACTION] UPDATE_CONTENT_SIDEBAR_CUSTOM_ENTITIES_BULK';
export const ADD_CONTENT_SIDEBAR_CUSTOM_ENTITIES = '[ACTION] ADD_CONTENT_SIDEBAR_CUSTOM_ENTITIES';
export const CLEAR_CONTENT_SIDEBAR_CUSTOM_ENTITIES = '[ACTION] CLEAR_CONTENT_SIDEBAR_CUSTOM_ENTITIES';

export function updateSidebarDomain(domain: CustomEntitiesSelectOption) {
	return {
		type: UPDATE_CONTENT_SIDEBAR_DOMAIN,
		payload: domain,
	};
}

export function updateSidebarEntityType(entityType: string) {
	return {
		type: UPDATE_CONTENT_SIDEBAR_ENTITY_TYPE,
		payload: entityType,
	};
}

export function updateSidebarCustomEntities(entities: CustomEntitiesSelectOption[], entityType: string) {
	return {
		type: UPDATE_CONTENT_SIDEBAR_CUSTOM_ENTITIES,
		payload: { data: entities, entityType },
	};
}

export function addSidebarCustomEntities(customEntities: CustomEntityBasic[]) {
	const data = customConnectionsToOptions(customEntities);

	return {
		type: ADD_CONTENT_SIDEBAR_CUSTOM_ENTITIES,
		payload: data,
	};
}

export function updateSidebarCustomEntitiesBulk(bulkEntities: Related[]) {
	return {
		type: UPDATE_CONTENT_SIDEBAR_CUSTOM_ENTITIES_BULK,
		payload: { bulkEntities },
	};
}

export function removeSidebarCustomEntity(entity: CustomEntitiesSelectOption) {
	return {
		type: REMOVE_CONTENT_SIDEBAR_CUSTOM_ENTITIES,
		payload: entity.additional || null,
	};
}

export function clearAddedCustomEntities() {
	return {
		type: CLEAR_CONTENT_SIDEBAR_CUSTOM_ENTITIES,
	};
}
