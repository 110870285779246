import React, { FunctionComponent, useEffect, useState } from 'react';
import Select from 'react-select';
import { FormGroup, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CustomEntitiesHttpService from '../../../../../../../services/rest/custom-entities.http.service';
import { CustomEntitiesSelectOption } from '../../../../../../Pages/CustomEntities/models/models';
import { toast } from 'react-toastify';

export const DomainSelect: FunctionComponent<{
	selectedDomain: CustomEntitiesSelectOption | null;
	onDomainSelectFunc: (type: CustomEntitiesSelectOption) => void;
}> = ({ selectedDomain, onDomainSelectFunc }) => {
	const { t } = useTranslation();
	const [domains, setDomains] = useState<CustomEntitiesSelectOption[]>([]);

	useEffect(() => {
		CustomEntitiesHttpService.getDomains()
			.then((response) => response.data.results)
			.then((data) => {
				const mappedData: CustomEntitiesSelectOption[] = data.map((item) => {
					return { label: item.name, value: item.id, data: item.slug };
				});

				setDomains(mappedData);
			})
			.catch(() => toast.error(t('error_fetching_domains')));
	}, []);

	const onDomainSelect = (selection: CustomEntitiesSelectOption) => onDomainSelectFunc(selection);

	if (domains.length === 0) {
		return null;
	}

	return (
		<FormGroup data-qa='domain-type-select'>
			{domains.length > 0 && (
				<Label htmlFor='domain-type-select' className='ml-1'>
					{t('domain')}
				</Label>
			)}
			<Select
				isClearable
				options={domains}
				inputId='domain-type-select'
				className='w-100 mb-4'
				placeholder={t('select')}
				value={selectedDomain}
				onChange={onDomainSelect}
				on
			/>
		</FormGroup>
	);
};
